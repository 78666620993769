<script lang="ts" setup></script>

<template>
  <el-container>
    <el-header class="main-header" height="auto">
      <AdminHeader />
    </el-header>
    <el-main>
      <slot />
    </el-main>
    <el-footer height="auto"> </el-footer>
  </el-container>
</template>

<style lang="scss" scoped>
.main-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--el-bg-color);
  box-shadow: inset 0 -1px 0 var(--el-border-color), 0 5px 10px #0002;
}
</style>
